import router from '@/router';

const scrollToView = (section: string) => {
  const element = document.querySelector(section);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export const scrollToSection = async (section: string) => {
  if (router.currentRoute.value.name !== 'Home') {
    router.push({ name: 'Home' }).then(() => {
      setTimeout(() => {
        scrollToView(section);
      }, 1000);
    });
  } else scrollToView(section);
};
