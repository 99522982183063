interface EmailData {
  name: string,
  email: string,
  phone?: string
  message: string
}

const EmailService = {
  sendEmail: (dataToSend: EmailData): Promise<boolean> => {
    return fetch(`${process.env.VUE_APP_API_URL}/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    }).then(res => {
      return res.status === 200;
    }).catch(err => {
      console.error(err);
      return false;
    });
  },
};

export default EmailService;
