
import Home from '@/sections/Home/Home.vue';
import HowItWorks from '@/sections/HowItWorks/HowItWorks.vue';
import Products from '@/sections/Products/Products.vue';
import Shopify from '@/sections/Shopify/Shopify.vue';
import Testimonials from '@/sections/Testimonials/Testimonials.vue';
import Pricing from '@/sections/Pricing/Pricing.vue';
import Plans from '@/sections/Plans/Plans.vue';
import ContactUs from '@/sections/ContactUs/ContactUs.vue';

export default {
  components: {
    Home,
    HowItWorks,
    Products,
    Shopify,
    Testimonials,
    Pricing,
    Plans,
    ContactUs,
  },
};
