import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/checkbox-checked.svg'
import _imports_1 from '@/assets/images/checkbox-unchecked.svg'


const _withScopeId = n => (_pushScopeId("data-v-6797383c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact-us__form-wrapper" }
const _hoisted_2 = {
  src: _imports_0,
  alt: "checkbox checked"
}
const _hoisted_3 = {
  src: _imports_1,
  alt: "checkbox unchecked"
}
const _hoisted_4 = { class: "terms" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_motion_fade = _resolveDirective("motion-fade")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormKit, {
      type: "form",
      "form-class": "contact-us__form",
      onSubmit: _ctx.sendMessage,
      actions: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormKit, {
          "outer-class": "form-input",
          "wrapper-class": "form-input__wrapper",
          "inner-class": "form-input__inner",
          "messages-class": "form-input__messages",
          "input-class": "form-input__input",
          type: "text",
          name: "name",
          placeholder: "Your Name",
          validation: "required|length:0,40"
        }),
        _createVNode(_component_FormKit, {
          "outer-class": "form-input",
          "wrapper-class": "form-input__wrapper",
          "inner-class": "form-input__inner",
          "messages-class": "form-input__messages",
          "input-class": "form-input__input",
          type: "text",
          name: "phone",
          placeholder: "Phone (Optional)",
          validation: "length:0,16"
        }),
        _createVNode(_component_FormKit, {
          "outer-class": "form-input",
          "wrapper-class": "form-input__wrapper",
          "inner-class": "form-input__inner",
          "messages-class": "form-input__messages",
          "input-class": "form-input__input",
          type: "text",
          name: "email",
          placeholder: "Email",
          validation: "required|email|length:0,320"
        }),
        _createVNode(_component_FormKit, {
          "outer-class": "form-input",
          "wrapper-class": "form-input__wrapper",
          "inner-class": "form-input__inner",
          "messages-class": "form-input__messages",
          "input-class": "form-input__textarea",
          type: "textarea",
          rows: "5",
          name: "message",
          placeholder: "Message",
          validation: "required|length:0,300"
        }),
        _createVNode(_component_FormKit, {
          "outer-class": "form-input__checkbox",
          "wrapper-class": "form-input__wrapper",
          "inner-class": "checkbox-inner",
          type: "checkbox",
          name: "terms",
          validation: "accepted",
          label: "Terms of Service",
          "decorator-icon": "stop"
        }, {
          decorator: _withCtx(({ node: { value } }) => [
            _withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [
              [_vShow, value]
            ]),
            _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
              [_vShow, !value]
            ])
          ]),
          label: _withCtx(() => [
            _createElementVNode("p", _hoisted_4, [
              _createTextVNode("I agree to the "),
              _createVNode(_component_router_link, { to: "/privacy" }, {
                default: _withCtx(() => [
                  _createTextVNode("Privacy Policy")
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(_component_Button, {
          delay: 200,
          "is-loading": _ctx.isEmailSending,
          primary: "",
          submit: "",
          text: "send message",
          class: "form-button"
        }, null, 8, ["is-loading"]), [
          [_directive_motion_fade]
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"])
  ]))
}