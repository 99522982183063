import { createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-613c6400"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "how-it-works",
  id: "howitworks"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "steps" }
const _hoisted_4 = ["delay"]
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "step__title" }
const _hoisted_7 = { class: "step__description" }
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_motion_slide_title_element = _resolveDirective("motion-slide-title-element")!
  const _directive_motion = _resolveDirective("motion")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("h1", _hoisted_2, [
      _createTextVNode(" Discover how it works ")
    ])), [
      [_directive_motion_slide_title_element]
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.steps, (step, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          key: step.title,
          class: "step",
          delay: (index + 1) * 230
        }, [
          _createElementVNode("img", {
            class: "step__image",
            src: step.image,
            alt: step.title
          }, null, 8, _hoisted_5),
          _createElementVNode("h3", _hoisted_6, _toDisplayString(step.title), 1),
          _createElementVNode("p", _hoisted_7, [
            _createTextVNode(_toDisplayString(step.description) + " ", 1),
            (step.link)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: $setup.CUSTOMIS3D_PROD
                }, "customis3d.app", 8, _hoisted_8))
              : _createCommentVNode("", true)
          ])
        ], 8, _hoisted_4)), [
          [_directive_motion, $setup.stepsAnimation()]
        ])
      }), 128))
    ])
  ]))
}