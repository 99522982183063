import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "returns-policy__content" }
const _hoisted_2 = { class: "returns-policy__content-heading" }
const _hoisted_3 = { class: "returns-policy__content-items pretty-scroll" }
const _hoisted_4 = { class: "content-item" }
const _hoisted_5 = { class: "content-item__text" }
const _hoisted_6 = { class: "content-item__text" }
const _hoisted_7 = { class: "content-item__text" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "content-item__list" }
const _hoisted_13 = { class: "list-item" }
const _hoisted_14 = { class: "list-item" }
const _hoisted_15 = { class: "content-item__text" }
const _hoisted_16 = { class: "content-item__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString($setup.ReturnsPolicyContent.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString($setup.ReturnsPolicyContent['paragraph-1']), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString($setup.ReturnsPolicyContent['paragraph-2']), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString($setup.ReturnsPolicyContent['paragraph-3']), 1),
        _createElementVNode("p", {
          class: "content-item__text",
          innerHTML: $setup.ReturnsPolicyContent['paragraph-4_html']
        }, null, 8, _hoisted_8),
        _createElementVNode("p", {
          class: "content-item__text",
          innerHTML: $setup.ReturnsPolicyContent['paragraph-5_html']
        }, null, 8, _hoisted_9),
        _createElementVNode("p", {
          class: "content-item__text",
          innerHTML: $setup.ReturnsPolicyContent['paragraph-6_html']
        }, null, 8, _hoisted_10),
        _createElementVNode("p", {
          class: "content-item__text",
          innerHTML: $setup.ReturnsPolicyContent['paragraph-7_html']
        }, null, 8, _hoisted_11),
        _createElementVNode("ol", _hoisted_12, [
          _createElementVNode("li", _hoisted_13, _toDisplayString($setup.ReturnsPolicyContent.list['item-1']), 1),
          _createElementVNode("li", _hoisted_14, _toDisplayString($setup.ReturnsPolicyContent.list['item-2']), 1)
        ]),
        _createElementVNode("p", _hoisted_15, _toDisplayString($setup.ReturnsPolicyContent['paragraph-8']), 1),
        _createElementVNode("p", _hoisted_16, _toDisplayString($setup.ReturnsPolicyContent['paragraph-9']), 1)
      ])
    ])
  ]))
}