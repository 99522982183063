export const PrivacyPolicyContent = {
  'title': 'Privacy Policy',
  'appIsCommittedToProtect__text': 'Customis3d is committed to protecting the Personal Data of the Users of our Services that are available through our Website. This policy describes our data protection practices and how we use and collect Personal Data. Our processing of your Personal Data is needed to deliver the Service to you. We may also process your Personal Data to comply with our legal obligations as explained below or due to our legitimate interests.',
  'personalData': {
    'title': 'PERSONAL DATA WE COLLECT',
    'text': {
      'paragraph_1': 'We will collect Personal Data from you in the following circumstances:',
      'paragraph_2': 'When you create an Account and/or place an order, we will collect your first and last name, E-mail address, address, phone number, password, drafts of product designs that you save under your Account, Content that you choose to keep under your user Account, communications and correspondence sent to and from your Account, information about purchasing habits and preferences, Order histories, and/or Account histories.',
      'paragraph_3': 'Currently, billing is handled by Shopify, so no payment information is taken,',
      'paragraph_4': 'When you invite others to use the platform, we may collect the date and time of the invite and to what e-mail addresses the invite was sent.',
      'paragraph_5': 'When you invite others to share your design library and related content, we may collect the e-mail addresses and other information you provide about the invitees.',
      'paragraph_6': 'When you contact us regarding products or orders, or for Customer Support or other customer service purposes, we may collect your name, e-mail address, phone number, and any communications from you to us.',
      'paragraph_7': 'If you save the names and postal or mailing addresses of any contacts under your Account; (where the feature is offered), we may collect such names and addresses.',
      'paragraph_8': 'When you create or design products: We may collect any images, text, logos or other content you upload or submit, including information related to or included within such scopes, such as the names, phone numbers and positions of individuals to whom the content is related.',
      'paragraph_9': 'Automatically collected information; We receive and store certain types of information through technology whenever you interact with us, some of which may contain Personal Data such as Cookies, Pixel Tags and Information collected by our servers. You will have a possibility to opt-out of us collecting this data. Please see further information in our Cookie Policy.',
    },
  },
  'purposeAndUseOfPersonalData': {
    'title': 'PURPOSE AND USE OF PERSONAL DATA',
    'text': {
      'paragraph_1': 'We use your Personal Data, including your content, for the following purposes:',
      'paragraph_2': 'We use Automatic Information to administer the Website and track user activities on the Website. We will create anonymous data records from Personal Data by excluding information (such as your name) that makes the data personally identifiable to you. We use such Anonymous Data records to analyse requests and usage patterns to enhance the Services content and improve Website navigation.',
    },
    'list': {
      'item_1': 'To provide you with the Services and to evaluate, modify and enhance the Services',
      'item_2': 'To enable you to set up your Account and to process and fulfil your Order',
      'item_3': 'To process your payment, facilitate billing and issue invoices as applicable',
      'item_4': 'To communicate with you and to respond to your requests',
      'item_5': 'To provide you with customer service and support',
      'item_6': 'For corporate Account management purposes',
      'item_7': 'To help keep our Website safe and secure and to improve the Website.',
    },
  },
  'sharePersonalData': {
    'title': 'HOW WE SHARE YOUR PERSONAL DATA',
    'text': {
      'paragraph_1': 'We disclose your Personal Data as described below. No Personal Data provided by you or that we may obtain automatically by your use of the Website is not and will not be sold, rented, or shared with any third party without your prior consent.',
      'subtitle_1': 'Transfer and Storage of Personal Data',
      'paragraph_2': "Your Personal Data will be transmitted, uploaded, transferred, stored, or backed up at Customis3d's servers with our GDPR-compliant cloud providers in the United States and Europe.",
      'subtitle_2': 'Members of our group',
      'paragraph_3': 'We may share your Personal Data with any member of our group, which means our subsidiaries, our ultimate holding company and any of its subsidiaries to perform our Services to you.',
      'subtitle_3': 'Third-Party Service Providers',
      'paragraph_4': 'We will share your Personal Data with third-party companies and individuals that perform Services on our behalf to help us provide the Platform and Services to you. For example, to fulfil your print Order in the most environmentally friendly way, we let the printer closest to the address of delivery print your products. This may mean a transfer of your personal data out of the E.U. Other examples of Services that Third Party Service Providers may provide may include, but are not limited to, processing credit card payments with our payment provider in the E.U., providing customer service by our suppliers in the E.U. and the Philippines, and maintaining our customer lists by our service providers in E.U. Third-Party Service Providers acting on our behalf are only provided with such Personal Data reasonably required to provide the particular Service for which they are retained. Our Third-Party Service Providers are obligated to keep all of your Personal Data confidential and to collect, use and disclose your Personal Data only to the extent necessary to provide the Services on our behalf. They fully comply with the EU GDPR and have signed a Data Processing Agreement with Customis3d.',
      'subtitle_4': 'Third-Party Payment Processor',
      'paragraph_5': 'Payment for services is taken via Shopify and passed to us. Therefore we hold no payment information.',
      'subtitle_5': 'Business Transfers',
      'paragraph_6': 'We may share some or all of your Personal Data in connection with or during the negotiation of any merger, financing, acquisition or dissolution, transaction or proceeding involving the sale, transfer, divestiture, or disclosure of all or a portion of our business or assets. In the event of insolvency, bankruptcy, or receivership, Personal Data may also be transferred as a business asset. Suppose another company acquires our company, business, or assets. In that case, that company will possess the Personal Data we have collected and assume the rights and obligations regarding your Personal Data as described in this Privacy Policy. The company may need your consent to continue handling your data.',
      'subtitle_6': 'Compliance with Law, Court Order, and Other Disclosures',
      'paragraph_7': 'You hereby acknowledge and agree that Customis3d may, in its sole discretion, release Account and other Personal Data when we believe such release is appropriate: (a) to comply with applicable law, statute, regulation, Court Order, or administrative proceeding; (b) in connection with any legal investigation; (c) to investigate or assist in preventing any violation or potential violation of this Privacy Policy or our Terms of Use; or (d) to protect the rights, property, or safety of Customis3d, our users, or others. This may include exchanging information for fraud protection and credit risk reduction with other companies and organisations.',
      'subtitle_7': 'Third Party Sites',
      'paragraph_8': "The Website may contain links to third-party Websites, e.g. Dropbox, or third-party Websites may otherwise be associated with the Website. These companies are GDPR compliant, and Customis3d has signed a Data Processing Agreement with them but is not responsible for the policies and practices employed by the owners of such third-party Websites, including but not limited to their collection, use and disclosure of your Personal Data, nor does Customis3d offer any (and expressly disclaims any) guarantee, representation, warranty, or covenant of any kind with respect to the collection, use or disclosure of your Personal Data by any third party Website that is linked from (or is otherwise associated with) the Website. Please consult any third-party website's terms and conditions and privacy policies before use.",
      'subtitle_8': 'Security of Your Personal Data',
      'paragraph_9': 'We employ security safeguards to protect your Personal Data against loss or theft and against unauthorised access, disclosure, copying, use, or modification. For example, when we transmit highly confidential information over the Internet, we protect it through encryption technology, such as the Secure Socket Layer (SSL) protocol. We also protect your stored password through the use of encryption technology.',
      'subtitle_9': 'International Data Transfers Privacy Shield and Contractual Terms',
      'paragraph_10': "Customis3d will transfer personal data to countries outside the E.U. due to our business model of printing locally and suppliers' premises outside the E.U.",
    },
    'list': {
      'item_1': 'When Personal Data is transferred to the U.S, we can verify that our Printers or Third parties have signed our Data Processing Agreement and are certified under the EU-US Privacy Shield and Swiss-US Privacy Shield framework or have signed the Standard Contractual Clauses.',
      'item_2': 'When Personal Data is transferred to other countries outside the E.U., we can verify that the Printers or Third parties have signed our Data Processing Agreement and the EU Standard Contractual Clauses.',
    },
  },
  'yourRights': {
    'title': 'YOUR RIGHTS REGARDING YOUR PERSONAL DATA',
    'text': {
      'subtitle_1': 'Cookies',
      'paragraph_1': 'Customis3d uses Cookies and similar technologies to provide and support our websites and Services, as more fully explained in our Cookie Policy; www. Customis3d.com/ cookiepolicy/. If you do not wish Cookies to be stored on your equipment or want to be notified when they are placed, you may set your web browser to do so or withdraw your consent from us. However, please note that if Cookies are turned off, you may not be able to view certain parts of the Website or Platform that may enhance your visit, and you may be unable to use some or all of the functionality of the Platform and Services.',
      'subtitle_2': 'Marketing Communication',
      'paragraph_2': '',
      'subtitle_3': 'Changing, Transferring or Deleting Your Personal Data',
      'paragraph_3': '',
    },
  },
  'dataControllersAndProcessors': {
    'title': 'IDENTIFYING DATA CONTROLLERS AND PROCESSORS',
    'text': "The EU GDPR regulation differentiates between the \"Data Controller\" and \"Data Processor\" of data. Our Customis3d Globe customers are Data Controllers of their personal data. Customis3d is also a Data Controller of your personal data. Our printers in our network and a few third-party suppliers are Data Processors. This means they are processing the data on your and Customis3d's behalf.",
  },
  'changesToPrivacyPolicy': {
    'title': 'CHANGES TO THIS PRIVACY POLICY',
    'text': 'We reserve the right to change this Privacy Policy at any time for any reason. If we change it, we will post the new one on our web page. Any non-material changes to this Privacy Policy will be effective as of the day they are posted. If we make any material change to this Privacy Policy, we will notify you by prominently posting notice of the changes on our Website. Any material changes to this Privacy Policy will be effective upon thirty (30) calendar days following our posting notice of the changes on our Website. These changes will be effective immediately for new users of our Service.',
  },
  'dataProtectionOfficer': {
    'title': 'DATA PROTECTION OFFICER AND CONTACT INFORMATION',
    'text': 'Suppose you believe Customis3d does not fulfill its obligations according to the EU GDPR regulation or other applicable privacy legislation. In that case, you also have the right to complain to a supervisory authority.',
  },
};
