import { createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c6c4bf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "products",
  id: "products"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  class: "slider-wrapper",
  delay: 300
}
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "slider__item-name" }
const _hoisted_6 = { class: "see-more" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_motion_slide_title_element = _resolveDirective("motion-slide-title-element")!
  const _directive_motion = _resolveDirective("motion")!
  const _directive_motion_fade_visible_once = _resolveDirective("motion-fade-visible-once")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("h1", _hoisted_2, [
      _createTextVNode(" Choose from a range of high-quality products that will boast your brand ")
    ])), [
      [_directive_motion_slide_title_element]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
      _createVNode(_component_swiper, {
        class: "slider",
        modules: $setup.modules,
        "slides-per-view": "auto",
        "space-between": 20,
        breakpoints: $setup.swiperBreakpoints,
        mousewheel: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, {
              class: "slider__item",
              key: item.title
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "slider__item-image",
                  src: item.image,
                  alt: item.title
                }, null, 8, _hoisted_4),
                _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["modules", "breakpoints"])
    ])), [
      [_directive_motion, $setup.sliderAnimation()]
    ]),
    _createElementVNode("div", _hoisted_6, [
      _withDirectives(_createVNode(_component_Button, {
        primary: "",
        "apply-color-to-text": "",
        class: "see-more__button",
        text: "See more",
        link: $setup.CUSTOMIS3D_PROD,
        icon: require('@/assets/images/arrow-right.svg')
      }, null, 8, ["link", "icon"]), [
        [_directive_motion_fade_visible_once]
      ])
    ])
  ]))
}