
import Button from '@/components/Button/Button.vue';
import { scrollToSection } from '@/utils/scrollToSection';
import { NavigationSections } from '@/constants/NavigationSections';

export default {
  components: { Button },
  setup() {
    return {
      scrollToSection,
      NavigationSections,
    };
  },
};
