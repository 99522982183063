
import { FreeMode, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import Button from '@/components/Button/Button.vue';
import { CUSTOMIS3D_PROD } from '@/constants/Urls';
import 'swiper/css/bundle';
import { isShowAnimationOnInit } from '@/constants/MotionDirectives';

export default {
  components: {
    Swiper,
    SwiperSlide,
    Button,
  },
  setup() {
    const items = [
      {
        image: require('@/assets/images/metal-wall-art.png'),
        title: 'Metal Wall Art',
      },
      {
        image: require('@/assets/images/blankets-and-towels.png'),
        title: 'Blankets & Towels',
      },
      {
        image: require('@/assets/images/colored-iphone.png'),
        title: 'Tech',
      },
    ];

    const swiperBreakpoints = {
      1920: {
        centeredSlides: true,
        centeredSlidesBounds: true,
      },
    };

    const sliderAnimation = () => {
      return {
        initial: {
          opacity: 0,
          x: 100,
        },
        [isShowAnimationOnInit ? 'enter' : 'visibleOnce']: {
          opacity: 1,
          x: 0,
          transition: {
            type: 'spring',
            stiffness: 120,
            damping: 12,
            mass: 0.8,
          },
        },
      };
    };

    return {
      items,
      modules: [ FreeMode, Mousewheel ],
      swiperBreakpoints,
      CUSTOMIS3D_PROD,
      sliderAnimation,
    };
  },
};
