import Home from '@/views/Home/Home.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy/PrivacyPolicy.vue';
import ReturnsPolicy from '@/views/ReturnsPolicy/ReturnsPolicy.vue';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: PrivacyPolicy,
  },
  {
    path: '/returns',
    name: 'Returns',
    component: ReturnsPolicy,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
