
import { ReturnsPolicyContent } from './ReturnsPolicyContent';

export default {
  setup() {
    return {
      ReturnsPolicyContent,
    };
  },
};
