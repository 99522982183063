
import Button from '@/components/Button/Button.vue';
import { Urls } from '@/constants/Urls';
export default {
  components: { Button },
  setup() {
    return {
      Urls,
    };
  },
};
