
import { isUpperThan } from '@/utils/mobile';
import { isShowAnimationOnInit } from '@/constants/MotionDirectives';
import { Urls, CUSTOMIS3D_PROD } from '@/constants/Urls';

export default {
  setup() {
    const steps = [
      {
        image: require('@/assets/images/hand.png'),
        title: 'Connect to Customis3d',
        description: 'Install our app with only a few clicks from the Shopify App store and connect your store by going to ',
        link: 'https://customis3d.app',
      },
      {
        image: require('@/assets/images/color-palette.png'),
        title: 'Product Creation',
        description: 'Apply your designs to any of our products and use our 3D editor to create and add to your store. The custom preview will be uploaded automatically! Don’t forget you can use our blank templates to let your customers personalise the products themselves.',
      },
      {
        image: require('@/assets/images/megaphone.png'),
        title: 'Get Selling',
        description: 'Finalise the product details in your store and link them to your collections. Then, when you are ready, activate the product and get selling.',
      },
      {
        image: require('@/assets/images/shipping-box.png'),
        title: 'Leave it to us',
        description: 'We will print, pack and ship your product to your customer under your brand. By taking on all this, you can concentrate on developing your brand.',
      },
    ];

    const stepsAnimation = () => {
      const isShowAnimationOnPageLoad = isUpperThan(2300) || isShowAnimationOnInit;
      return {
        initial: {
          opacity: 0,
        },
        [isShowAnimationOnPageLoad ? 'enter' : 'visibleOnce']: {
          opacity: 1,
        },
      };
    };

    return {
      steps,
      stepsAnimation,
      Urls,
      CUSTOMIS3D_PROD,
    };
  },
};
