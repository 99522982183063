const useButton = ({ link }: {link: string}) => {
  const redirectToLink = () => {
    if (link) {
      window.open(link, '_blank');
    }
  };
  return {
    redirectToLink,
  };
};

export default useButton;
