
import { SetupContext } from '@vue/runtime-core';
import useVideoModal from './VideoModal';
export default {
  props: {
    videoLink: {
      type: String,
      required: true,
    },
  },
  setup(_: any, { emit }: SetupContext) {
    return useVideoModal(emit);
  },
};
