
import { isLowerThan } from '@/utils/mobile';
import { Breakpoints } from '@/constants/Breakpoints';
import HeaderDesktop from './HeaderDesktop/HeaderDesktop.vue';
import HeaderMobile from './HeaderMobile/HeaderMobile.vue';

export default {
  components: {
    HeaderDesktop,
    HeaderMobile,
  },
  setup() {
    return {
      isLowerThan,
      Breakpoints,
    };
  },
};
