
export default {
  setup() {
    const plans = [
      {
        title: 'Free',
        subtitle: 'For merchants starting a business',
        price: '0',
        highlighted: true,
        isCustomPrice: false,
        description: [
          {
            highlightedText: '5',
            text: '1 store per account',
          },
          {
            highlightedText: 'Unlimited',
            text: 'Unlimited Products designs',
          },
        ],
      },
    ];

    const insertHighlightedText = (highlightedText: string, text: string) => {
      return text ? text.replace(highlightedText, `<span class="bold-text">${highlightedText}</span>`) : '';
    };

    return {
      plans,
      insertHighlightedText,
    };
  },
};
