
import { NavigationSections } from '@/constants/NavigationSections';
import { scrollToSection } from '@/utils/scrollToSection';
import { Urls } from '@/constants/Urls';

export default {
  setup() {
    return {
      scrollToSection,
      NavigationSections,
      Urls,
    };
  },
};
