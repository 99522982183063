import { Breakpoints } from '@/constants/Breakpoints';
import { isLowerThan, isUpperThan } from '@/utils/mobile';

export const isShowAnimationOnInit =
  isUpperThan(Breakpoints.MinLargeMonitorWidth) ||
  (
    isLowerThan(Breakpoints.MinLaptopWidth) &&
    isUpperThan(Breakpoints.MaxTabletWidth)
  ) ||
  window.innerHeight > 1050;

export const MotionDirectives = {
  'slide-to-top': {
    initial: {
      y: 30,
      opacity: 0,
    },
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 180,
        damping: 40,
        mass: 0.5,
      },
    },
  },
  'slide-from-bottom': {
    initial: {
      y: 30,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
  },
  'slide-from-left': {
    initial: {
      x: -100,
      opacity: 0,
    },
    enter: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 500,
        type: 'spring',
        stiffness: 120,
        damping: 10,
        mass: 0.6,
      },
    },
  },
  'slide-title-element': {
    initial: {
      y: -50,
      opacity: 0,
    },
    [isUpperThan(Breakpoints.MaxLaptopWidth) ? 'enter' : 'visibleOnce']: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1000,
        delay: 200,
      },
    },
  },
};
