import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4099e4c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "burger-menu__close" }
const _hoisted_2 = { class: "burger-menu__body" }
const _hoisted_3 = { class: "navigation-list" }
const _hoisted_4 = {
  class: "navigation-list__item",
  delay: 100
}
const _hoisted_5 = {
  class: "navigation-list__item",
  delay: 200
}
const _hoisted_6 = {
  class: "navigation-list__item",
  delay: 300
}
const _hoisted_7 = {
  class: "navigation-list__item",
  delay: 400
}
const _hoisted_8 = {
  class: "navigation-list__item",
  delay: 500
}
const _hoisted_9 = { class: "burger-menu__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _directive_motion_fade_visible = _resolveDirective("motion-fade-visible")!
  const _directive_motion = _resolveDirective("motion")!
  const _directive_motion_slide_from_bottom = _resolveDirective("motion-slide-from-bottom")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["burger-menu", { 'burger-menu__open': $props.open }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createVNode(_component_Button, {
          "icon-button": "",
          icon: require('@/assets/images/close-button-black.svg'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, null, 8, ["icon"]), [
          [_directive_motion_fade_visible]
        ])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("ul", _hoisted_3, [
          _withDirectives((_openBlock(), _createElementBlock("li", _hoisted_4, [
            _createElementVNode("a", {
              class: "navigation-list__item-link",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('close'), $setup.scrollToSection($setup.NavigationSections.HowItWorks)), ["stop"]))
            }, " How It Works ")
          ])), [
            [_directive_motion, $setup.slideFromRight()]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("li", _hoisted_5, [
            _createElementVNode("a", {
              class: "navigation-list__item-link",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('close'), $setup.scrollToSection($setup.NavigationSections.Products)), ["stop"]))
            }, " Products ")
          ])), [
            [_directive_motion, $setup.slideFromRight()]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("li", _hoisted_6, [
            _createElementVNode("a", {
              class: "navigation-list__item-link",
              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('close'), $setup.scrollToSection($setup.NavigationSections.Shopify)), ["stop"]))
            }, " Shopify ")
          ])), [
            [_directive_motion, $setup.slideFromRight()]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("li", _hoisted_7, [
            _createElementVNode("a", {
              class: "navigation-list__item-link",
              onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.$emit('close'), $setup.scrollToSection($setup.NavigationSections.Testimonials)), ["stop"]))
            }, " Testimonials ")
          ])), [
            [_directive_motion, $setup.slideFromRight()]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("li", _hoisted_8, [
            _createElementVNode("a", {
              class: "navigation-list__item-link",
              onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.$emit('close'), $setup.scrollToSection($setup.NavigationSections.Plans)), ["stop"]))
            }, " Pricing ")
          ])), [
            [_directive_motion, $setup.slideFromRight()]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _withDirectives(_createVNode(_component_Button, {
          delay: 200,
          secondary: "",
          class: "contact-us",
          text: "Contact us",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('close'), $setup.scrollToSection($setup.NavigationSections.ContactUs)))
        }, null, 512), [
          [_directive_motion_slide_from_bottom]
        ])
      ])
    ], 2),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          class: "burger-menu-overlay",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('close')))
        }, null, 512), [
          [_vShow, $props.open]
        ])
      ]),
      _: 1
    })
  ], 64))
}