import { createApp } from 'vue';
import App from './App.vue';
import vClickOutside from 'click-outside-vue3';
import { plugin as FormKitPlugin, defaultConfig } from '@formkit/vue';
import { MotionPlugin } from '@vueuse/motion';
import { MotionDirectives } from '@/constants/MotionDirectives';
import router from './router';

createApp(App)
  .use(router)
  .use(vClickOutside)
  .use(FormKitPlugin, defaultConfig({
    theme: 'genesis',
  }))
  .use(MotionPlugin, {
    directives: MotionDirectives,
  })
  .mount('#app');
