export const ReturnsPolicyContent = {
  'title': 'Returns Policy',
  'paragraph-1': 'Any claims for misprinted/damaged/defective items must be submitted within 30 days of receiving the product. Photo evidence is required for a valid claim. Additionally, all claims must be submitted for packages lost in transit no later than 30 days after the estimated delivery date. However, this depends on the selected shipping method, for Claims deemed an error are covered at our expense.',
  'paragraph-2': 'Please email your request if you or your customers notice an issue with the products or anything else.',
  'paragraph-3': 'The return address is set by default to the Customis3d facility. When we receive a returned shipment, an automated email notification will be sent to you. Unclaimed returns get donated to charity after 30 days. If Customis3d\'s facility isn\'t used as the return address, you will become liable for any returned shipments you receive.',
  'paragraph-4_html': '<strong>Wrong Address</strong> - If you or your end customer provide an address considered insufficient by the courier, the shipment will be returned to our facility. You will be liable for reshipment costs once we have confirmed an updated address with you (if and as applicable).',
  'paragraph-5_html': '<strong>Unclaimed</strong> - Shipments that go unclaimed are returned to our facility, and you will be liable for the cost of a reshipment to yourself or your end customer (if and as applicable).',
  'paragraph-6_html': '<strong>Returned by Customer</strong> - It is best to advise your end customers to contact you before returning any products. Except for Customers residing in Brazil, we do not refund orders for buyer\'s remorse. Returns for products and size exchanges are to be offered at your expense and discretion. For example, if you accept returns or offer size exchanges to your end customers, you would need to place a new order at your expense for a product in another size. Customers residing in Brazil and regretting a purchase must contact our Customer Service and express their will to return the item within seven consecutive days after receiving it, providing a picture of the item. The withdrawal request will be evaluated to verify whether the product was used or destroyed, even partially. In these cases, a refund will not be possible',
  'paragraph-7_html': '<strong>Notification for EU consumers:</strong> According to Article 16(c) and (e) of Directive 2011/83/EU of the European Parliament and of the Council of 25 October 2011 on consumer rights, the right of withdrawal may not be provided for:',
  'list': {
    'item-1': "the supply of goods that are made to the consumer's specifications or are personalized;",
    'item-2': "sealed goods which were unsealed after delivery and thus aren't suitable for return due to health protection or hygiene reasons,",
  },
  'paragraph-8': 'therefore Customis3d reserves the right to refuse returns at its sole discretion.',
  'paragraph-9': 'This Policy shall be governed and interpreted in English, regardless of any translations made for any purpose.',
};
