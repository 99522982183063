import { scrollToSection } from '@/utils/scrollToSection';
import { NavigationSections } from '@/constants/NavigationSections';
import { ref } from 'vue';

const useHeaderMobile = () => {
  const isBurgerMenuOpen = ref(false);
  const openBurgerMenu = () => {
    isBurgerMenuOpen.value = true;
    document.body.style.overflowY = 'hidden';
    document.documentElement.style.overflowY = 'hidden';
  };

  const closeBurgerMenu = () => {
    isBurgerMenuOpen.value = false;
    document.body.style.overflowY = 'auto';
    document.documentElement.style.overflowY = 'auto';
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return {
    scrollToSection,
    NavigationSections,
    isBurgerMenuOpen,
    openBurgerMenu,
    closeBurgerMenu,
    scrollToTop,
  };
};

export default useHeaderMobile;
