import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Home = _resolveComponent("Home", true)!
  const _component_HowItWorks = _resolveComponent("HowItWorks")!
  const _component_Products = _resolveComponent("Products")!
  const _component_Shopify = _resolveComponent("Shopify")!
  const _component_Testimonials = _resolveComponent("Testimonials")!
  const _component_Pricing = _resolveComponent("Pricing")!
  const _component_Plans = _resolveComponent("Plans")!
  const _component_ContactUs = _resolveComponent("ContactUs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Home),
    _createVNode(_component_HowItWorks),
    _createVNode(_component_Products),
    _createVNode(_component_Shopify),
    _createVNode(_component_Testimonials),
    _createVNode(_component_Pricing),
    _createVNode(_component_Plans),
    _createVNode(_component_ContactUs)
  ], 64))
}