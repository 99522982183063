
import { PrivacyPolicyContent } from './PrivacyPolicyContent';
import { ProductInfo } from '@/constants/ProductInfo';

export default {
  setup() {
    return {
      ProductInfo,
      PrivacyPolicyContent,
    };
  },
};
