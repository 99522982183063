
import Button from '@/components/Button/Button.vue';
import VideoModal from '@/components/VideoModal/VideoModal.vue';
import { Urls, CUSTOMIS3D_PROD } from '@/constants/Urls';
import { ref } from 'vue';

export default {
  components: {
    Button,
    VideoModal,
  },
  setup() {
    const isModalOpen = ref(false);
    return {
      isModalOpen,
      Urls,
      CUSTOMIS3D_PROD,
    };
  },
};
