
import Button from '@/components/Button/Button.vue';
import { scrollToSection } from '@/utils/scrollToSection';
import { NavigationSections } from '@/constants/NavigationSections';

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  components: { Button },
  setup() {
    const slideFromRight = () => {
      return {
        initial: {
          x: 50,
          opacity: 0,
        },
        visible: {
          x: 0,
          opacity: 1,
          transition: {
            type: 'spring',
            stiffness: 180,
            damping: 12,
            mass: 0.5,
          },
        },
      };
    };
    return {
      slideFromRight,
      scrollToSection,
      NavigationSections,
    };
  },
};
