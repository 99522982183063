import { onBeforeUnmount, onMounted, SetupContext } from '@vue/runtime-core';

const useVideoModal = (emit: SetupContext['emit']) => {
  onMounted(() => {
    document.body.style.overflowY = 'hidden';
    document.documentElement.style.overflowY = 'hidden';
  });
  onBeforeUnmount(() => {
    document.body.style.overflowY = 'auto';
    document.documentElement.style.overflowY = 'auto';
  });
  const close = (e: Event) => {
    if ((e.target as HTMLElement).className === 'modal-wrapper') {
      emit('close');
    }
  };
  return {
    close,
  };
};

export default useVideoModal;
