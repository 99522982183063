
import Button from '@/components/Button/Button.vue';
import ContactUsForm from './ContactUsForm/ContactUsForm.vue';
import useContactUs from './useContactUs';

export default {
  components: {
    ContactUsForm,
    Button,
  },
  setup() {
    return useContactUs();
  },
};
