
export default {
  setup() {
    const testimonials = [
      {
        avatar: require('@/assets/images/testimonials1.png'),
        name: 'The Case Store',
        location: 'United Kingdom',
        text: 'Integrates seamlessly with Shopify, high quality products and 3D Preview is amazing. I hope to add more products soon.',
        rating: 5,
      },
      {
        avatar: require('@/assets/images/testimonials2.png'),
        name: 'Yoga Heaven',
        location: 'United Kingdom',
        text: 'Time spent using app 21 days Product creation is amazing, can add new designs to my store in minutes. Water Bottles would be a good additions.',
        rating: 5,
      },
      {
        avatar: require('@/assets/images/testimonials3.png'),
        name: 'Belated',
        location: 'United Kingdom',
        text: 'Time spent using app 45 days Top quality products, my customers are giving me great reviews. Towel and Blankets are the best I have seen. Pricing is the best of any Print on Demand apps.',
        rating: 5,
      },
    ];

    return {
      testimonials,
    };
  },
};
