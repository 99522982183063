
import Button from '@/components/Button/Button.vue';
import { CUSTOMIS3D_PROD } from '@/constants/Urls';

export default {
  components: {
    Button,
  },
  setup() {
    return {
      CUSTOMIS3D_PROD,
    };
  },
};
