
import Button from '@/components/Button/Button.vue';
import { SetupContext } from 'vue';
import useContactUsForm from './useContactUsForm';

export default {
  components: {
    Button,
  },
  setup(_: any, { emit }: SetupContext) {
    return useContactUsForm(emit);
  },
};
