
import Button from '@/components/Button/Button.vue';
import BurgerMenu from '@/components/BurgerMenu/BurgerMenu.vue';
import useHeaderMobile from './HeaderMobile';

export default {
  components: {
    Button,
    BurgerMenu,
  },
  setup() {
    return useHeaderMobile();
  },
};
