import { ref } from 'vue';

const useContactUs = () => {
  const isEmailSent = ref(false);
  const isEmailSending = ref(false);

  return {
    isEmailSent,
    isEmailSending,
  };
};

export default useContactUs;
