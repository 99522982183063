import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/button-loader.svg'


const _withScopeId = n => (_pushScopeId("data-v-8af9f12a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "disabled"]
const _hoisted_2 = {
  key: 0,
  class: "button-text"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "button-loader",
  src: _imports_0,
  alt: "button-loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass({
      'primary': $props.primary,
      'secondary': $props.secondary,
      'icon': $props.icon,
      'icon-button': $props.iconButton,
      'apply-color-to-text': $props.applyColorToText,
      'black': $props.black
    }),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.redirectToLink && _ctx.redirectToLink(...args))),
    type: $props.submit ? 'submit' : 'button',
    disabled: $props.isLoading
  }, [
    (!$props.iconButton && !$props.isLoading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($props.text), 1))
      : _createCommentVNode("", true),
    ($props.icon && !$props.isLoading)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: $props.icon,
          alt: "icon",
          class: "button-icon"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    ($props.isLoading)
      ? (_openBlock(), _createElementBlock("img", _hoisted_4))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}