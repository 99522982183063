import EmailService from '@/services/Email';
import { Urls } from '@/constants/Urls';
import { ref, SetupContext } from 'vue';

const useContactUsForm = (emit: SetupContext['emit']) => {
  const isEmailSending = ref(false);

  const sendMessage = async (e: any) => {
    const dataToSend = {
      name: e.name,
      phone: e.phone || '',
      email: e.email,
      message: e.message,
    };

    isEmailSending.value = true;
    const isEmailSent = await EmailService.sendEmail(dataToSend);
    if (isEmailSent) emit('sent');
    isEmailSending.value = false;
  };
  return {
    sendMessage,
    Urls,
    isEmailSending,
  };
};

export default useContactUsForm;
